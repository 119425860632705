@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap');

.noto {

	// Noto Serif JP（明朝）
	&-serif {
		font-family: 'Noto Serif JP',
			serif;
	}

	// Noto Sans JP（ゴシック）
	&-sans {
		font-family: 'Noto Sans JP',
			sans-serif;
	}
}

.fw {

	// Thin 100（ゴシックのみ）
	&-100 {
		font-weight: 100;
	}

	// Extra-light 200（明朝のみ）
	&-200 {
		font-weight: 200;
	}

	// Light 300
	&-300 {
		font-weight: 300;
	}

	// Regular 400
	&-400 {
		font-weight: 400;
	}

	// Medium 500
	&-500 {
		font-weight: 500;
	}

	// Semi-bold 600（明朝のみ）
	&-600 {
		font-weight: 600;
	}

	// Bold 700
	&-700 {
		font-weight: 700;
	}

	// Black 900
	&-900 {
		font-weight: 900;
	}
}