@charset "UTF-8";
/* カラー */
// Color system

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(("100": $gray-100,
		"200": $gray-200,
		"300": $gray-300,
		"400": $gray-400,
		"500": $gray-500,
		"600": $gray-600,
		"700": $gray-700,
		"800": $gray-800,
		"900": $gray-900),
	$grays);

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
//$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
// 追加色
$navy: #3551a2;
$orange: #ed6d3d;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(("blue": $blue,
		"indigo": $indigo,
		"purple": $purple,
		"pink": $pink,
		"red": $red,
		"orange": $orange,
		"yellow": $yellow,
		"green": $green,
		"teal": $teal,
		"cyan": $cyan,
		"white": $white,
		"gray": $gray-600,
		"gray-dark": $gray-800),
	$colors);

// $primary: $blue;
$primary: $navy;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
//$warning: $yellow;
$warning: $orange;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

/* スペーサー */
// $spacer: 1rem;
$spacer: 0.875rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((0: 0,
		1: ($spacer * .25),
		2: ($spacer * .5),
		3: $spacer,
		4: ($spacer * 1.5),
		5: ($spacer * 3)),
	$spacers);

// Typography

/* フォントサイズ */
/*
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
*/
$font-size-base: 0.9375rem; // 標準フォントサイズ：15px
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * .875;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
// $line-height-base: 1.5;
$line-height-base: 1.75; //標準行高さ：1.75

// $h1-font-size: $font-size-base * 2.5;
// $h2-font-size: $font-size-base * 2;
// $h3-font-size: $font-size-base * 1.75;
// $h4-font-size: $font-size-base * 1.5;
// $h5-font-size: $font-size-base * 1.25;
// $h6-font-size: $font-size-base;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

$headings-margin-bottom: $spacer / 2;
$headings-font-family: null;
$headings-font-weight: 500;
$headings-line-height: 1.5;
$headings-color: null;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$display-line-height: $headings-line-height;

$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: 80%;

$text-muted: $gray-600;

$blockquote-small-color: $gray-600;
$blockquote-small-font-size: $small-font-size;
$blockquote-font-size: $font-size-base * 1.25;

$hr-border-color: rgba($black, .1);
// $hr-border-width: $border-width;

$mark-padding: .2em;

$dt-font-weight: $font-weight-bold;

$kbd-box-shadow: inset 0 -.1rem 0 rgba($black, .25);
$nested-kbd-font-weight: $font-weight-bold;

$list-inline-padding: .5rem;

$mark-bg: #fcf8e3;

$hr-margin-y: $spacer;

// デフォルトのdarkを1段階濃く
// $dark: #212529;

// デフォルトのパンくずリストの区切り記号
$breadcrumb-divider: quote(">");

/* ボタンサイズ調整 */
//角丸変数
$border-radius: .1rem;
$border-radius-lg: .1rem;
$border-radius-sm: .1rem;
//余白サイズ
$input-btn-padding-y: .375rem;
// $input-btn-padding-x: .75rem;
$input-btn-padding-x: 1.5rem;