@charset "UTF-8";

// フォント設定
body {
	font-family: -apple-system,
		BlinkMacSystemFont,
		Roboto,
		"Segoe UI",
		"Helvetica Neue",
		HelveticaNeue,
		YuGothic,
		"Yu Gothic Medium",
		"Yu Gothic",
		Verdana,
		Meiryo,
		sans-serif;
	color: #352f2d;
}

b,
strong,
.bold {
	font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI semibold", "Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic", "Segoe UI", Verdana, Meiryo, sans-serif;
}



// モバイル時の左右ブレ防止
.wrapper {
	width: 100%;
	overflow: hidden !important;
}

// ナビバー
.sticky-top {
	z-index: 50;
}

/* メインビジュアル */
.jumbotron {
	height: 130vw;
	background: none;

	//	@media (max-width: 991px) {
	//	 	height: 40vw;
	//	}

	@media (min-width: 576px) {
		height: 50vw;
	}

	@media (min-width: 768px) {
		height: 40vw;
	}

	@media (min-width: 992px) {
		height: 35vw;
	}

	.display-4 {
		@media (max-width: 768px) {
			font-size: 2rem;
		}

		@media (max-width: 992px) {
			font-size: 2.5rem;
		}
	}
}


/* 背景画像設定 */

// スライド背景
.slide-01 {
	background-image: url(img/slide-01-min.jpg);
	background-size: cover;
	background-position: right 60%;
	//background-attachment: fixed;
}

.slide-02 {
	background-image: url(img/slide-02-min.jpg);
	background-size: cover;
	background-position: 85% bottom;
	//background-attachment: fixed;
}

.slide-03 {
	background-image: url(img/slide-03-min.jpg);
	background-size: cover;
	background-position: right 10%;
	//background-attachment: fixed;
}

// 背景シンボル
.bg-mark-w {
	background-image: url(img/1x/.bg-mark-w.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
}

.bg-mark-b {
	background-image: url(img/1x/.bg-mark-b.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
}

.bg-city {
	background-image: url(img/bg-underconstruction-8-min.png);
	background-size: cover;
	background-position: center center;
}

// コンテンツ見出し背景

.title-bg-message {
	background-image: url(img/1x/bg-message-secondary.png);
	background-repeat: no-repeat;
	background-position: left center;
	background-size: contain;
}

.title-bg-solution {
	background-image: url(img/1x/bg-solution-secondary.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
}

.title-bg-cases {
	background-image: url(img/1x/bg-cases-dark.png);
	background-repeat: no-repeat;
	background-position: center center;
}

.title-bg-company {
	background-image: url(img/1x/bg-company-dark.png);
	background-repeat: no-repeat;
	background-position: center center;
}

.title-bg-information {
	background-image: url(img/1x/bg-information-light.png);
	background-repeat: no-repeat;
	background-position: center center;
}

.case-heading {
	padding-left: 1.25em;
	background-image: url(img/SVG/mark-tri-heading.svg);
	background-repeat: no-repeat;
	background-size: 0.8em;
	background-position: 0 0.25em;
}


/* マップ、フォーム（iframe埋込） */
iframe {
	border: none;
}

// メールフォームのレイアウト（modal内調整）
#contact-form .modal-body,
#contact-form .panel-body {
	padding: 0;
	margin: 0;
}

/* テーブル */
caption {
	caption-side: top;
}

/* モーダル */
.modal-body {
	td {
		padding-left: 0.5em;
	}
}

.modal-content {
	text-shadow: -1px 1px 7px rgba(255, 255, 255, 0.9);
	caption-side: top;
}

.modal-body {

	dd tr:first-child th,
	dd tr:first-child td {
		border-top: none;
	}
}

// 開閉ボタン
a:hover[data-toggle] {

	img {
		opacity: 0.7;
		transition-duration: .2s;
	}
}

/* リンク保留 */
a.nav-link {
	font-size: 1rem;
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 0.8);
}

/* navbarのリンク色 */
.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.65);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: rgba(0, 0, 0, 0.95);
}

/* Under Construction表示 */
.area-underconstruction {
	padding: 50vw 0;

	@media (min-width: 768px) {
		padding: 20vw 0;
	}

	@media (min-width: 1200px) {
		padding: 15vw 0;
	}
}


// アイコンの色調整
.card-title .fas {
	color: #ed6d3d;
}

// テキストシャドー

.heading-shadow {
	text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);
}

.heading-shadow .btn {
	text-shadow: none;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

/* lightbox、IE11対応 */
// body.fixed {
// 	position: fixed;
// 	width: 100%;
// 	height: 100%;
// }